import React, {Component} from 'react';
import {IRootState} from "../../store/AppStore";
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import NotFound from "../pages/NotFound";
import PageLoading from "../shared/PageLoading";
import {Spinner} from "reactstrap";
import {Toast} from "../../util/Toast";
import {validation} from "../../locale/en/validation";
import * as Yup from "yup";

Yup.setLocale(validation);

Toast.config({
    top: 80,
    duration: 2,
    maxCount: 3,
});

const mapStateToProps = ({ui}: IRootState) => {
    return {ui};
};

const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const DefaultLayout = React.lazy(() => import('../layouts/DefaultLayout'));

type ReduxType = ReturnType<typeof mapStateToProps>;

const loading = () =>
<div className="app-loader">
    <Spinner color="primary" />
</div>;

class AppContainer extends Component <ReduxType> {
    render() {
        return (
            <>
            <PageLoading show={this.props.ui.showPageLoading}/>
            <Router>
                <React.Suspense fallback={loading()}>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/*" element={<DefaultLayout />} />
                        <Route element={<NotFound />} />
                    </Routes>
                </React.Suspense>
            </Router>
            </>
        );
    }
}

export default connect(mapStateToProps)(AppContainer);
