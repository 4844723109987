import { createRoot } from "react-dom/client";
// import 'antd/lib/table/style/css';
// import 'antd/lib/drawer/style/css';
// import 'antd/lib/message/style/css';
// import 'antd/lib/modal/style/css';
// import 'antd/lib/date-picker/style/css';
// import 'antd/lib/time-picker/style/css';
// import 'antd/lib/upload/style/css';
// import 'antd/lib/input-number/style/css';
// import 'antd/lib/auto-complete/style/css';
// import 'antd/lib/switch/style/css';
// import 'antd/lib/notification/style/css';
// import 'antd/lib/transfer/style/css';
// import 'antd/lib/slider/style/css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './style/style.blue.css';
// import './style/modify.scss';
// import './style/all.css';
import './style/all.scss';
import './style/all-mediaquery.scss';
// import './style/modify.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    // TODO: Fix component did mount twice in React 18 Strict Mode. or implementing effect clean up every where
    //  https://beta.reactjs.org/learn/synchronizing-with-effects#you-might-not-need-an-effect
    // root.render(
    //     <React.StrictMode>
    //         <App />
    //     </React.StrictMode>
    // );

    root.render(<App />);
}
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
