import {message} from "antd";

export class Toast {
    static config(config: any) {
        message.config(config);
    }

    static success(text: any) {
        message.success(text);
    }

    static error(text: any) {
        message.error(text);
    }
}
