import qs from "qs";
import {validation} from "../locale/en/validation";
import {Toast} from "./Toast";
import moment from "moment-timezone";
import {notification} from "antd";
import React, { useLayoutEffect, useState } from 'react';
import Auth from "./Auth";
import AppConstants from "./AppConstants";


export function getListData(getList: any, listQueryParams: any, props: any) {
    props.showPageLoading();
    return getList(listQueryParams).then((data: any) => {
        props.hidePageLoading();
        return Promise.resolve(data);
    }).catch((response: any) => {
        Toast.error('Can not load Error :' + response);
        return Promise.reject(response);
    });
}

export function exportDataToPdf(getList: any, params: any = {}, listQueryParams: any = {}, callback: any = null) {
    delete listQueryParams.page;
    delete listQueryParams.pageSize;
    return getList(params, listQueryParams).then((response: any) => {
        downloadFile('assetMileageDailySummary.csv', response);
    }).catch((response: any) => {
        Toast.error('Cannot Export Data' + response);
        return Promise.reject(response);
    });
}

export function exportDataToCsv(getList: any, params: any = {}, listQueryParams: any = {}, callback: any = null) {
    delete listQueryParams.page;
    delete listQueryParams.pageSize;
    return getList(params, listQueryParams).then((response: any) => {
        let data = response.data.data;
        let removeColumns: any = ["createDate", "modifyDate", "modifyDate", "createUser", "modifySystem","modifyUser","timeZoneId"];
        let columns: any = [];
        if (data.length > 0) {
            columns = Object.keys(data[0]);
            removeColumns = [...columns.filter((item: string) => {
                return item.indexOf('HashId') !== -1;
            }), ...removeColumns];
        }

        data = data.map((item: any) => {
            const row = (callback) ? {...callback(item)} : {...item};
            const keys = Object.keys(row);
            keys.forEach((key: any) => {
                if (removeColumns.includes(key)) {
                    delete row[key];
                    return;
                }
                row[key] = ((row[key] === null || row[key] === undefined)) ? `""` : `"${row[key]}"`;
            });

            return {
                ...row,
                ...item.startDate && {startDate: utcDateToLocalDate(item.startDate)},
                ...item.endDate && {endDate: utcDateToLocalDate(item.endDate)},
                ...item.lastOdometerDate && {lastOdometerDate: utcDateToLocalDate(item.lastOdometerDate)},
                ...item.lastOdometerDate && {lastOdometerDate: utcDateToLocalDate(item.lastOdometerDate)},
            };
        });
        exportToCsvFile(data);
        return Promise.resolve({});
    }).catch((response: any) => {
        Toast.error('Cannot Export Data' + response);
        return Promise.reject(response);
    });
}

export function downloadFile(fileName: string, response: any) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
}


export function pushListQuery(history: any, location: any, filters: any) {
    const queryParams = qs.parse(location.search.substr(1));
    history.push({
        pathname: location.pathname,
        search: qs.stringify({
                ...queryParams,
                filters: filters,
                page: 1,
                pageSize: 10,
            },
        ),
        state: queryParams
    });
}

export function clearListQuery(history: any, location: any) {
    history.push({
        pathname: location.pathname,
        search: '',
        state: ''
    });
}

export const groupBy = (items: any, key: any) => items.reduce(
    (result: any, item: any) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

export function sortByDateTime(datetimeA: string|null, dateTimeB: string|null) {
    if (datetimeA && dateTimeB) {
        return moment(datetimeA).unix() - moment(dateTimeB).unix();
    }
    return null;
}

export function sortByDateTimeWithLocal(datetimeA: string|null, dateTimeB: string|null) {
    if (datetimeA && dateTimeB) {
        const valueA: any = utcDateToLocalDate(datetimeA, AppConstants.SERVER_DATE_FORMAT, AppConstants.DEFAULT_DATE_FORMAT);
        const valueB: any = utcDateToLocalDate(dateTimeB, AppConstants.SERVER_DATE_FORMAT, AppConstants.DEFAULT_DATE_FORMAT);
        return sortByDateTime(valueA, valueB);
    }
    return null;
}

export function sortByString(valueA: string, valueB: string) {
    return valueA.length - valueB.length;
}

export function utcDateToLocalDate(date: string, fromFormat: string = AppConstants.SERVER_DATE_FORMAT, toFormat: string = AppConstants.DEFAULT_DATE_FORMAT) {
    if (!date) {
        return null;
    }

    const auth = new Auth();
    const utcDate = moment.utc(date, fromFormat);
    return utcDate.clone().tz(auth.getTimezone()).format(toFormat);
}

export function dateToUtcDate(date: string | null, fromFormat: string = 'YYYY-MM-DD[T]HH:mm:ss') {
    if (!date) {
        return null;
    }
    const auth = new Auth();
    return moment.tz(date, fromFormat, auth.getTimezone()).clone().utc().format(fromFormat);
}

export function GetMetricLabel() {
    const useMile = Auth.getUseMile();
    return (useMile) ? "Km" : "Miles";
}

export function GetMetricConvertedPM(value: string) {
    const useMile = Auth.getUseMile();
    if (useMile) {
        const valueArray = value
            .split(';')
            .map((item: string) => {
                if (item.toLowerCase().includes("km")) {
                    return GetMetricValue(item.toLowerCase().replace("km", "").trim()) + " Miles";
                }
                return item;
            });
        return valueArray.join("; ");
    }
    return value;
}

export function GetMetricValue(value: any, fractionDigits = 2) {
    if (!value) return '';
    const useMile = Auth.getUseMile();
    const numberValue: number = Number(value);
    return (useMile) ? numberValue.toFixed(fractionDigits) : (numberValue/1.609).toFixed(fractionDigits);
}

export function SetMetricValue(value: any) {
    if (!value) return '';
    const useMile = Auth.getUseMile();
    const numberValue: number = Number(value);
    return (useMile) ? numberValue.toFixed(2) : (numberValue*1.609).toFixed(2);
}

export function MileToKm(value: number) {
    return (value*1.609).toFixed(2)
}

export function KmToMile(value: number) {
    return (value/1.609).toFixed(2)
}

export function findUnique(array: any, column: string) {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if(!map.has(item[column])){
            map.set(item[column], true); // set any value to Map
            result.push(item);
        }
    }
    return result;
}

export function animatedMove(marker:any, t:any, current:any, moveto:any) {
    let lat = moveto.lat();
    let lng = moveto.lng();

    var deltalat = (lat - current.lat()) / 100;
    var deltalng = (lng - current.lng()) / 100;

    var delay = 10 * t;
    for (var i = 0; i < 100; i++) {
        (function(ind) {
            setTimeout(
                function() {
                    var lat = marker.getPosition().lat();
                    var lng = marker.getPosition().lng();
                    lat += deltalat;
                    lng += deltalng;
                    const latlng = new window.google.maps.LatLng(lat, lng);
                    marker.setPosition(latlng);
                }, delay * ind
            );
        })(i)
    }
}

export function startOfDayToUtcDate(date: string | null, fromFormat: string = 'YYYY-MM-DD[T]HH:mm:ss') {
    if (!date) {
        return null;
    }
    const auth = new Auth();
    const localDate = moment.tz(date, fromFormat, auth.getTimezone()).set({hours: 0, minutes: 0, second: 0});
    return localDate.clone().utc().format('YYYY-MM-DD[T]HH:mm:ss');
}

export function endOfDayToUtcDate(date: string | null, fromFormat: string = 'YYYY-MM-DD[T]HH:mm:ss') {
    if (!date) {
        return null;
    }
    const auth = new Auth();
    const localDate = moment.tz(date, fromFormat, auth.getTimezone()).set({hours: 23, minutes: 59, second: 59});
    return localDate.clone().utc().format('YYYY-MM-DD[T]HH:mm:ss');
}

export function getValidationLocale(locale = 'en') {
    return validation;
}

export function showNotification(title: string, type: any, description: any) {
    notification.open({
        type: type,
        message: title,
        description: description,
        duration: 10,
    });
}

class ErrorMessage extends React.Component<{ errors: any }> {
    render() {
        const errors = this.props.errors;
        return (
            <ul>
                {errors.map((item: any, index: any) => {
                    const messageText = item.messageText || item.MessageText || '';
                    return (
                        <li key={index}>{messageText}</li>
                    )
                })}
            </ul>
        )
    }
}

export  function getInterval(value: number | null) {
    if (!value) return "";
    const intervals: any = AppConstants.INTERVAL;
    const intervalKeyValues = Object
        .keys(intervals)
        .map((key: string) => {
            return {key: key, value: intervals[key]}
        });
    const interval = intervalKeyValues.find((item: any) => item.value === value);
    return interval && interval.key.toLowerCase();
}

export function showErrors(error: any) {
    if (error.response) {
        const response = JSON.parse(JSON.stringify(error.response));

        const errors = response.data.Errors || response.data.errors || null;
        if (response.status === 401 && response.statusText === "Unauthorized") {
            const auth = new Auth();
            auth.logout();
            window.location.replace('/login');
        }
        if (errors) {
            showNotification('Alert', 'error', (<ErrorMessage errors={errors}/>))
        }

    } else if (error.request) {
        // The request was made but no response was received
        //console.log(error.message);
        Toast.error(error.message);
    } else {
        // Something happened in setting up the request that triggered an Error
        //console.log(error.message);
        Toast.error(error.message);
    }
}

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export function startCase(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

function convertToCSV(objArray: any) {
    if (objArray.length === 0) {
        return '';
    }

    objArray.unshift(Object.keys(objArray[0]).map(item => startCase(item)));

    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ',';

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportToCsvFile(jsonData: any) {
    const csvStr = convertToCSV(jsonData);
    const blob = new Blob([csvStr], { type: 'data:text/csv;charset=utf-8;' });
    const exportedFileName = "data.csv";
    // if (navigator.msSaveBlob) { // IE 10+
    //     navigator.msSaveBlob(blob, exportedFileName);
    // } else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    // }
}

export function isValidJsonString(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getUrlFromPath(string: any) {
    if(!string) {
        return '';
    }
    let path = string.replace(/\\/g, "/");
    path = process.env.REACT_APP_HOST_URL + path;
    return path;
}
export function getBase64Path(string: any) {
    if(!string) {
        return '';
    }
    return string.replace(/\\/g, "/");
}
export function copyToClipboard(str:string){
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function escapeSpecialCharacters(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const useWindowResize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};