import moment from "moment-timezone";
import AppConstants from "./AppConstants";

interface IOrganization {
    organizationHashId: string | null,
    parentId: number | null,
    timeZoneId: number | null,
    isAdmin: boolean,
    organizationName: string | null,
    parentHashId: string | null,
    createUser: string | null,
    createDate: string | null,
    modifyDate: string | null,
    modifyUser: string | null,
    modifySystem: string | null,
    logo: string | null,
    isPatchMapEnabled: boolean,
    isDispatchModuleEnabled: boolean,
    parentOrganizationLogo: string | null,
}

interface Claims {
    claimType: string,
    claimValue: string,
}

interface IRole {
    id: string,
    name: string,
    normalizedName: string,
    claims: Claims[]
}

interface User {
    userHashId: string,
    organizationHashId: string,
    aspNetUserId: number | null,
    firstName: string,
    lastName: string,
    useMile: boolean,
    email: string,
    phoneNumber: string | null,
    createUser: string | null,
    createDate: string,
    modifyUser: string | null,
    modifyDate: string | null,
    modifySystem: string | null,
    organization:IOrganization,
    role:IRole[],
    currentOrganization?: IOrganization,
    currentRole?: IRole,
    claims:[],
}


interface IAuth {
    token: string,
    user:User
}

//export const defaultMapLocation = {lat:39.305,lng:-76.617};
export const defaultMapLocation = {lat:21.248787,lng:81.638299};
export const defaultMapZoom:number = 9;

export default class Auth {
    storage = localStorage;

    isLoggedIn(): boolean {
        return !!this.getUser();
    }

    getToken(): any {
        const user = this.getUser();

        return (user) ? user.token : null;
    }

    getUser(): IAuth | null {
        const user = this.getItemFromStorage('user');

        return (user) ? JSON.parse(user) : null;
    }

    static getUseMile(): boolean {
        const user = localStorage.getItem('user');
        return (user) ? JSON.parse(user).user.useMile : false;
    }

    getCurrentOrganization(): IOrganization | null {
        const user = this.getUser();
        return (user && user.user.currentOrganization) ? user.user.currentOrganization : null;
    }

    getCurrentOrganizationId(): string {
        const organization = this.getCurrentOrganization();
        return (organization && organization.organizationHashId) ? organization.organizationHashId : "";
    }

    setCurrentOrganization(organization: IOrganization) {
        const user = this.getUser();
        if (user) {
            user.user.currentOrganization = organization;
            this.setItemToStorage('user', JSON.stringify(user));
        }
    }

    getMapLocation(){
        try {
            return this.getItemFromStorage('mapLocation')  || defaultMapLocation;
        }catch (e) {
            return defaultMapLocation;
        }
    }

    setMapLocation(location:any){
        this.setItemToStorage('mapLocation', JSON.stringify(location));
    }

    getMapZoom(){
        try{
            const localZoom = this.getItemFromStorage('mapZoom');
            if(localZoom){
                return parseInt(localZoom);
            }
            return defaultMapZoom;
        }catch (e) {
            return defaultMapZoom;
        }
    }

    setMapZoom(zoom:any){
        this.setItemToStorage('mapZoom', JSON.stringify(zoom));
    }

    isPatchMapEnabled() {
        const organization = this.getCurrentOrganization();
        return !!(organization && organization.isPatchMapEnabled);
    }

    isSuperAdmin() {
        const user = this.getUser();
        const role = this.getCurrentRole();
        if (role) {
            const isTopLeafOrg = (user && user.user.organization && !user.user.organization.parentHashId);
            return (role.normalizedName === AppConstants.ROLES.SUPERADMIN && isTopLeafOrg);
        }
        return false;
    }

    isTopLevelOrgAndHasPermission(permission: string) {
        const user = this.getUser();
        const isTopLeafOrg = (user && user.user.organization && !user.user.organization.parentHashId);
        const hasPermission = this.hasPermission(permission);

        return (isTopLeafOrg && hasPermission);
    }

    isAdmin() {
        const user = this.getUser();
        return (user && user.user.organization && user.user.organization.isAdmin);
    }

    getClaims() {
        const user = this.getUser();
        return (user && user.user.currentRole && user.user.currentRole.claims) ? user.user.currentRole.claims : [];
    }

    hasPermission(permission: string) : boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            return claims.some((item: any) => {
                return item.claimValue === permission;
            });
        }
        return false;
    }

    hasPermissions(permissions: string[] = []) : boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            const intersection = claims.filter((item: any) => {
                return permissions.includes(item.claimValue);
            });
            return intersection.length === permissions.length;
        }
        return false;
    }

    hasAnyPermissions(permissions: string[] = []) : boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            return claims.some((item: any) => {
                return permissions.includes(item.claimValue);
            });
        }
        return false;
    }

    getCurrentRole():IRole | null {
        const user = this.getUser();
        return (user && user.user.currentRole) ? user.user.currentRole : null;
    }

    setCurrentRole(role: IRole) {
        const user = this.getUser();
        if (user) {
            user.user.currentRole = role;
            this.setItemToStorage('user', JSON.stringify(user));
        }
    }

    setUser(user: IAuth): void {
        user.user.currentOrganization = user.user.organization;
        user.user.currentRole = user.user.role[0];

        this.setItemToStorage('user', JSON.stringify(user));
    }

    getTimezone(): any{
        const user: any = this.getUser();
        if (user && user.user.timeZoneName) {
            return (moment.tz.zone(user.user.timeZoneName)) ? user.user.timeZoneName : moment.tz.guess();
        } else if (user && user.user.organization.timeZone) {
            return (moment.tz.zone(user.user.organization.timeZone.timeZoneName)) ? user.user.organization.timeZone.timeZoneName : moment.tz.guess();
        } else {
            return moment.tz.guess();
        }
    }

    setLoginRedirectUrl(url: string, queryParams: any) {
        this.setItemToStorage('redirect', JSON.stringify({
            url: url, queryParams: queryParams
        }));
    }

    getLoginRedirectUrl() {
        if (this.getItemFromStorage('redirect')){
            return JSON.parse(this.getItemFromStorage('redirect'));
        }
        return null;
    }

    removeLoginRedirectUrl() {
        this.storage.removeItem('redirect');
    }

    setDashboardFilter(value: boolean) {
        this.setItemToStorage('dashboard-asset-filter', value);
    }

    getDashboardFilter() {
        const showFilter = this.getItemFromStorage('dashboard-asset-filter');
        return (showFilter && showFilter === "true");
    }

    getItemFromStorage(key: any): any {
        return this.storage.getItem(key);
    }

    setItemToStorage(key: any, value: any): void {
        this.storage.setItem(key, value);
    }

    logout(): void {
        this.storage.removeItem('user');
    }
}
export const auth = new Auth();